import {
  useShowInstructions,
  type LayoutModule,
} from '@backstage-components/base';
import {useSubscription} from 'observable-hooks';
import {PropsWithChildren, useEffect, useMemo, useRef, type FC} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigate as useRouterNavigate,
  type NavigateFunction,
} from 'react-router';
import {reactName, RouterInstructionSchema} from './RouterDefinition';

export type RouterComponentDefinition = LayoutModule<
  typeof reactName,
  RouterContainerProps
>;

/**
 * Creates a `react-router` `Routes` node which also subscribes to `Router`
 * instructions in order to manage page navigation with the site `Instruction`
 * flows.
 */
export const RouterContainer: FC<PropsWithChildren<RouterContainerProps>> = (
  props
) => {
  const location = useLocation();
  const navigate = useRouterNavigate();
  const {observable, broadcast} = useShowInstructions(RouterInstructionSchema);
  // Use a ref rather than state so that the useSubscription function can only
  // be a closure over the correct value because the ref is mutated in place
  const navigateFn = useRef(props.navigate ?? navigate);
  // There are two places that broadcast `:on-navigate` messages but both should
  // not trigger off of `Router:goto`. Setting `shouldBroadcastOnLocationChange`
  // allows the next `:on-navigate` broadcast to be skipped when a location
  // change is detected
  const shouldBroadcastOnLocationChange = useRef(true);
  useEffect(() => {
    navigateFn.current = props.navigate ?? navigate;
  }, [navigate, props.navigate]);

  useSubscription(observable, {
    next: (instruction) => {
      if (instruction.type === 'Router:goto') {
        broadcast({
          type: 'Router:on-navigate',
          meta: {currentPath: instruction.meta.path},
        });
        // skip the next `Router:on-navigate` broadcast
        shouldBroadcastOnLocationChange.current = false;
        // perform the navigation
        navigateFn.current(`${props.prefix ?? ''}${instruction.meta.path}`);
        // when navigation is explicitly performed scroll to the top
        window.scrollTo({left: 0, top: 0});
      } else if (instruction.type === 'Router:redirect') {
        window.location.href = instruction.meta.url;
      }
    },
  });

  const currentPath = useMemo(() => {
    const pathname = props.prefix
      ? location.pathname.replace(props.prefix, '')
      : location.pathname;
    return pathname === '' ? '/' : pathname;
  }, [location.pathname, props.prefix]);

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const result: Record<string, string | string[]> = {};
    for (const key of params.keys()) {
      const values = params.getAll(key);
      const head = values.at(0);
      if (values.length === 1 && typeof head === 'string') {
        result[key] = head;
      } else if (values.length > 1) {
        result[key] = values;
      }
    }
    return result;
  }, [location.search]);

  // The `UiRouter` in `@backstage/ui-render` always produces at least a
  // fallback route so the minimum number of routes (before data loads) is 1
  const routes = useMemo(
    () => createRoutesFromChildren(props.children),
    [props.children]
  );
  const hasRoutes = useMemo(() => routes.length > 1, [routes]);

  // broadcast a route change instruction when currentPath changes
  useEffect(() => {
    if (!hasRoutes) {
      return;
    }

    if (shouldBroadcastOnLocationChange.current) {
      broadcast({
        type: 'Router:on-navigate',
        meta: {currentPath},
      });
    }
    shouldBroadcastOnLocationChange.current = true;

    const timeout = setTimeout(() =>
      broadcast({
        type: 'Router:on-navigate-done',
        meta: {currentPath, query},
      })
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [broadcast, currentPath, hasRoutes, query]);

  // broadcast on-404-no-match instruction if no routes were matched
  useEffect(() => {
    if (!hasRoutes) {
      return;
    }
    const matches = matchRoutes(routes, location);
    if (matches?.[0]?.route.path === '*') {
      broadcast({
        type: 'Router:on-404-no-match',
        meta: {currentPath},
      });
    }
  }, [broadcast, currentPath, hasRoutes, routes, location]);

  return <Routes>{props.children}</Routes>;
};

export interface RouterContainerProps {
  /**
   * Function called in order to navigate between pages, this function is used
   * in processing `Router:goto` instructions.
   * @default `useNavigate` from `react-router` package
   */
  navigate?: NavigateFunction;
  /**
   * If provided, `prefix` is prepended to every path before navigation is
   * triggered.
   */
  prefix?: string;
}
